let environment = {
  environmenName: 'dev',
  production: false,
  apiURL: 'https://platform-rest-api.azurewebsites.net/api/v1/contactus'
};

if (window.location.origin === 'https://www.staging.techfriends.com.br') {
  environment = {
    environmenName: 'homolog',
    production: false,
    apiURL: 'https://platform-rest-api.azurewebsites.net/api/v1/contactus'
  };
}

if (window.location.origin === 'https://plusdigital.techfriends.com.br') {
  environment = {
    environmenName: 'prod',
    production: true,
    apiURL: 'https://plus-digital-production-platform-apis.azurewebsites.net/api/v1/contactus'
  };
}
export { environment };
